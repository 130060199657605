// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-details-js": () => import("./../../../src/pages/career-details.js" /* webpackChunkName: "component---src-pages-career-details-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-comingsoon-js": () => import("./../../../src/pages/comingsoon.js" /* webpackChunkName: "component---src-pages-comingsoon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-1-js": () => import("./../../../src/pages/home-1.js" /* webpackChunkName: "component---src-pages-home-1-js" */),
  "component---src-pages-home-2-js": () => import("./../../../src/pages/home-2.js" /* webpackChunkName: "component---src-pages-home-2-js" */),
  "component---src-pages-home-3-js": () => import("./../../../src/pages/home-3.js" /* webpackChunkName: "component---src-pages-home-3-js" */),
  "component---src-pages-home-4-js": () => import("./../../../src/pages/home-4.js" /* webpackChunkName: "component---src-pages-home-4-js" */),
  "component---src-pages-home-5-js": () => import("./../../../src/pages/home-5.js" /* webpackChunkName: "component---src-pages-home-5-js" */),
  "component---src-pages-home-6-js": () => import("./../../../src/pages/home-6.js" /* webpackChunkName: "component---src-pages-home-6-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-reset-pass-js": () => import("./../../../src/pages/reset-pass.js" /* webpackChunkName: "component---src-pages-reset-pass-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-signin-image-js": () => import("./../../../src/pages/signin-image.js" /* webpackChunkName: "component---src-pages-signin-image-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-image-js": () => import("./../../../src/pages/signup-image.js" /* webpackChunkName: "component---src-pages-signup-image-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

